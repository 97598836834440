import { css, html, LitElement } from 'lit';
import { property } from 'lit/decorators';

// dialog-component.js

class LitDialog extends LitElement {
  @property({ type: String }) width = '300px';
  @property({ type: String }) height = 'auto';

  static styles = css`
    .dialog {
      display: none;
      position: fixed;
      z-index: 1000;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .dialog-content {
      background-color: #fff;
      margin: 15% auto;

      width: var(--dialog-width, 300px);
      height: var(--dialog-height, auto);
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
      position: relative;
    }
  `;

  static properties = {
    width: { type: String },
    height: { type: String },
  };

  @property({ type: Boolean }) isOpen;

  constructor() {
    super();
    this.isOpen = false;
  }

  render() {
    return html`
      <div
        class="dialog"
        style="display: ${this.isOpen
          ? 'flex'
          : 'none'}; justify-content: center; align-items: center;"
      >
        <div
          class="dialog-content"
          style="--dialog-width: ${this.width}; --dialog-height: ${this
            .height};"
        >
          <slot></slot>
        </div>
      </div>
    `;
  }

  openDialog() {
    this.isOpen = true;
  }

  closeDialog() {
    this.isOpen = false;
  }
}

customElements.define('lit-dialog', LitDialog);
