import '@sentry/wrapper';
import './lit-dialog';

import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import { locales } from './locales';

interface DialogElement extends HTMLElement {
  openDialog: () => void;
  closeDialog: () => void;
}
declare global {
  interface Window {
    adobeid: any;
    adobeIMS: any;
    SENTRY_EVENTS: any[];
  }
}

function preRedirectWork() {
  return new Promise(resolve => {
    setTimeout(resolve, 3000);
  });
}

const searchParams = new URLSearchParams(window.location.search);
const authParams = [...searchParams.entries()].filter(
  ([key]) => key !== 'config'
);
const config = JSON.parse(searchParams.get('config') ?? '{}');
const isPopup = !!config.popup;

@customElement('sample-app')
export class SampleApp extends LitElement {
  static styles = css`
    * {
      box-sizing: border-box;
    }

    :host section {
      display: grid;
      grid-template-rows: 65px auto;
      background: var(--spectrum-gray-75) !important;
    }

    :host main {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow-y: auto;
    }

    :host,
    section {
      width: 100%;
      height: 100%;
    }

    progress {
      visibility: hidden;
      width: 334px;
      margin-bottom: 20px;
    }

    progress.show {
      visibility: visible;
    }

    .language-picker {
      background-color: var(--spectrum-gray-75);
      border: solid 1px var(--spectrum-gray-200);
      color: var(--spectrum-gray-900);
      margin-left: 10px;
      padding: 5px;
    }

    .theme-button {
      background-color: var(--spectrum-gray-75);
      border: solid 1px var(--spectrum-gray-200);
      color: var(--spectrum-gray-900);
      margin-left: 10px;
      padding: 5px;
    }

    .card {
      display: flex;
      justify-content: center;
      align-items: center;

      background: var(--spectrum-gray-75);
      border-radius: 4px;
      border: solid 1px var(--spectrum-gray-200);
    }

    .card-express-theme {
      width: 360px;
      height: 500px;
      padding: 32px;
    }

    .card-large-buttons {
      width: 334px;
      height: 482px;
      padding: 26px;
    }

    .card-complete-account {
      width: 510px;
      height: 700px;
      padding: 56px;
    }

    .card iframe {
      width: 100%;
      height: 100%;
    }

    .code {
      width: 600px;
      font-size: 18px;
      word-break: break-word;
    }

    .adobe-logo {
      width: 85px;
      display: flex;
      justify-content: space-between;
    }

    .adobe-logo__text {
      font-weight: 700;
      font-size: 18px;
      color: #fa0f00;
    }

    .global-nav {
      display: flex;
      justify-content: center;
      background: var(--spectrum-gray-75);
      border-bottom: solid 1px var(--spectrum-gray-200);
      color: var(--spectrum-gray-900);
    }

    .global-nav__navigation {
      max-width: 1440px;
      flex-grow: 1;
    }

    .global-nav__navigation-list {
      display: flex;
    }

    .global-nav,
    .global-nav__navigation-list,
    .global-nav__navigation-list-item {
      height: 100%;
    }

    .global-nav__navigation-list,
    .global-nav__navigation-list-item {
      padding: 0;
      margin: 0;
    }

    .global-nav__navigation-list-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: adobe-clean, Source Sans Pro, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin: 0 16px;
    }

    .global-nav__navigation-list-item--right {
      margin-left: auto;
    }

    sp-switch {
      color: var(--spectrum-gray-900);
      font-family: adobe-clean, Source Sans Pro, -apple-system,
        BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
      font-size: 14px;
      padding: 10px;
    }

    .options-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
    }

    .redirect-link {
      font-family: adobe-clean, Source Sans Pro, -apple-system;
      text-decoration: none;
    }

    section {
      height: 100%;
    }

    sp-theme {
      height: 100%;
    }
  `;

  @property()
  isPopup = isPopup;

  @property({ type: Object })
  userData: { displayName: string; email: string } | null = null;

  @property()
  token = '';

  @property({ type: Object })
  authState = {
    client_id: 'sentry-test',
    scope: 'AdobeID,openid',
    locale: 'en-us',
    response_type: 'token',
    ...authParams.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
    dt: this.isDarkMode,
  };

  @property({ type: Object })
  config = {
    ...config,
    consentProfile: config.consentProfile ?? 'adobe-id-sign-up',
    enableCompleteAccount: config.enableCompleteAccount ?? false,
  };

  connectedCallback() {
    super.connectedCallback();
    window.SENTRY_EVENTS = [];
    const qs = new URLSearchParams(window.location.hash.substring(1));
    if (qs.has('access_token')) {
      this._onToken({ detail: qs.get('access_token') } as CustomEvent<string>);
    }
  }

  get isDarkMode() {
    const [_, dtValue] = authParams.find(([key]) => key === 'dt') || [];

    return dtValue
      ? dtValue === 'true'
      : window?.matchMedia('(prefers-color-scheme: dark)')?.matches;
  }

  protected firstUpdated(
    _changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>
  ): void {
    [
      'on-load',
      'on-auth-failed',
      'on-analytics',
      'on-modal-open',
      'on-modal-close',
    ].forEach(ev => {
      const sentryElement = this.shadowRoot?.querySelector('#sentry');
      if (sentryElement) {
        sentryElement.addEventListener(ev, e => {
          window.SENTRY_EVENTS.push({
            name: e.type,
            detail: e.detail,
          });
        });
      }
    });
  }

  async _onRedirect(e: any) {
    const redirectUri = e.detail;
    await preRedirectWork.apply(this);
    window.location.assign(redirectUri);
  }

  _onProviderClicked(e: CustomEvent) {
    console.log('provider clicked', e.detail);
  }

  async _onToken(e: CustomEvent<string>) {
    const token = e.detail;
    if (searchParams.get('disable_local_msw') === 'true') {
      this.token = token;
    }

    await window.adobeIMS.refreshToken();
    this.userData = await window.adobeIMS.getProfile();
  }

  async _onAuthCode(e: CustomEvent) {
    const code = e.detail;
    if (searchParams.get('disable_local_msw') === 'true') {
      this.token = code;
    }
  }

  async _onAuthFailed(e: CustomEvent) {
    if (e.detail.reason === 'popup-blocked') {
      const redirectUri = e.detail.fallbackUrl;
      await preRedirectWork.apply(this);
      window.location.assign(redirectUri);
    }
  }

  async _onAnalytics(e: CustomEvent) {
    console.log(e);
  }

  async _onError(e: CustomEvent) {
    if (e.detail.name === 'critical') {
      console.error('critical', e);
    }

    if (e.detail.name === 'unrecoverable') {
      console.error('unrecoverable', e);
    }
  }

  onThemeChange() {
    this.authState = {
      ...this.authState,
      dt: !this.authState.dt,
    };
  }

  get variant() {
    if (config.variant) {
      return config.variant;
    }

    return 'large-buttons';
  }

  get authParams() {
    const extraParams: Record<string, string> = {};
    const serviceParams: Record<string, string> = {};

    extraParams['redirect_uri'] =
      'https://auth-light-sample.identity-stage.adobe.com';

    if (searchParams.get('disable_local_msw') === 'true') {
      serviceParams['disable_local_msw'] = 'true';
    }

    return { ...this.authState, ...extraParams, ...serviceParams };
  }

  localeChanged = (e: any) => {
    this.authState = {
      ...this.authParams,
      locale: e.target.value,
    };
  };

  @property()
  dialogOpen = false;

  @property()
  dialogDimensions: { width: number; height: number } = { width: 0, height: 0 };

  openDialog(dimensions: { width: number; height: number }) {
    const dialog = this.shadowRoot?.getElementById('dialog') as DialogElement;
    if (dialog && 'openDialog' in dialog) {
      dialog.openDialog();
    }
    this.dialogOpen = true;
    this.dialogDimensions = dimensions;
  }

  closeDialog() {
    const dialog = this.shadowRoot?.getElementById('dialog') as DialogElement;
    if (dialog) {
      dialog.closeDialog();
    }
    this.dialogOpen = false;
  }

  susiLight = () => {
    return html`<susi-sentry
      id="sentry"
      .authParams=${this.authParams}
      .config=${this.config}
      .popup=${this.isPopup}
      .variant=${this.variant}
      @on-auth-code=${this._onAuthCode}
      @on-auth-failed=${this._onAuthFailed}
      @on-error=${this._onError}
      @on-load=${(e: CustomEvent) => console.log('onload', e.detail.ready)}
      @on-provider-clicked=${this._onProviderClicked}
      @on-modal-open=${e => {
        console.log('on complete account modal open', e.detail);
        this.openDialog(e.detail.dimensions);
      }}
      @on-modal-close=${e => {
        console.log('on complete account modal close', e.detail);
        this.closeDialog();
      }}
      @on-token=${this._onToken}
      @redirect=${this._onRedirect}
    ></susi-sentry>`;
  };

  susiLightElm = () => {
    return html`<susi-sentry-light
      id="sentry"
      .authParams=${this.authParams}
      .config=${this.config}
      .popup=${this.isPopup}
      .variant=${this.variant}
      @on-analytics=${this._onAnalytics}
      @on-auth-code=${this._onAuthCode}
      @on-auth-failed=${this._onAuthFailed}
      @on-error=${this._onError}
      @on-load=${(e: CustomEvent) => console.log('onload', e.detail.ready)}
      @on-provider-clicked=${this._onProviderClicked}
      @on-token=${this._onToken}
      @redirect=${this._onRedirect}
    ></susi-sentry-light>`;
  };

  render() {
    return html`
      <sp-theme theme="spectrum" color=${this.authState.dt ? 'dark' : 'light'}>
        <section>
          <header class="global-nav">
            <nav class="global-nav__navigation">
              <ul class="global-nav__navigation-list">
                <li class="global-nav__navigation-list-item">
                  <div class="adobe-logo">
                    <img
                      class="adobe-logo__icon"
                      src="https://www.adobe.com/content/dam/cc/icons/Adobe_Corporate_Horizontal_Red_HEX.svg"
                      width="25px"
                    />
                    <span class="adobe-logo__text">Adobe</span>
                  </div>
                </li>
                <li class="global-nav__navigation-list-item">
                  <a
                    href="https://git.corp.adobe.com/pages/adobe-identity/identity-sentry/"
                    class="redirect-link"
                    >Read Docs</a
                  >
                </li>
                <li class="global-nav__navigation-list-item">
                  <a
                    href="https://auth-light-tessera.identity-stage.adobe.com/"
                    class="redirect-link"
                    >React sample</a
                  >
                </li>
                <li
                  class="global-nav__navigation-list-item global-nav__navigation-list-item--right"
                >
                  ${this.userData
                    ? html`<span
                        >Signed in as ${this.userData?.displayName}</span
                      >`
                    : html`<span>Sign in</span>`}
                  ${this.userData?.email
                    ? html`<span>(${this.userData?.email})</span>`
                    : nothing}
                </li>
              </ul>
            </nav>
          </header>
          <main>
            ${this.token
              ? html`<section id="token">${this.token}</section>`
              : nothing}
            <div class="options-container">
              <select class="language-picker" @change=${this.localeChanged}>
                ${locales.map(
                  locale => html`
                    <option
                      value=${locale}
                      ?selected=${locale === this.authParams.locale}
                    >
                      ${locale}
                    </option>
                  `
                )}
              </select>
              <button
                class="theme-button"
                type="button"
                @click=${this.onThemeChange}
              >
                Dark Mode: ${this.authState.dt ? 'ON ' : 'OFF'}
              </button>
            </div>
            <div class=${`card card-${this.variant}`}>
              ${['edu-express', 'standard', 'sandbox'].includes(this.variant)
                ? this.susiLightElm()
                : this.susiLight()}
            </div>

            <lit-dialog
              width=${`${this.dialogDimensions.width}px`}
              height=${`${this.dialogDimensions.height}px`}
              id="dialog"
            >
            </lit-dialog>
          </main>
        </section>
      </sp-theme>
    `;
  }
}
